@import 'styles/variables.scss';

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, .4);
  z-index: 1000;

  &__fieldSenha{
    position: relative;
  }

  &__fieldSenha{
    input{
      width: 100%;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    margin: 42px 0;
    border: 1px solid #ddd;
    border-radius: 3px;
    max-width: 96%;
    width: 420px;
    padding: 24px;
    height: max-content;
    background-color: #fff;
  }

  &__title {
    font-size: $f19;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
  }

  &__cadastrar {
    margin-top: 8px;
    color: #111;

    &:hover {
      text-decoration: underline;
    }
  }

  label {
    font-size: $f15;
    margin-bottom: 8px;
  }

  &__field {
    margin-bottom: 22px;
    height: 40px;
    border: 1px solid #ddd;
    padding: 12px;
    box-sizing: border-box;
  }
}