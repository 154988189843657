@import 'styles/variables.scss';

.btn__cadastro {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  background-color: #111;
  color: #fff;
  min-height: 38px;
  border-radius: 3px;
  border: 0;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, .3);
  text-transform: uppercase;
  font-size: $f12;
  letter-spacing: 0.8px;
  cursor: pointer;
}

.contentHorarios {
  display: flex;
  justify-content: start;
  flex-direction: column;
  max-width: 96%;
  width: 580px;
  margin: 42px auto;

  label {
    font-size: $f15;
    margin-bottom: 8px;
  }
}

.listFormasPagamento {
  border: 1px solid #666;
  min-height: 22px;
  border-radius: 8px;
  padding: 12px;
  background-color: #ddd;
  display: flex;
  flex-wrap: wrap;

  li {
    background-color: #111;
    max-width: max-content;
    padding: 6px 14px;
    border-radius: 3px;
    color: #fff;
    margin-right: 12px;
  }
}

.foto {
  max-width: 150px;
  margin: 8px 0;

  img {
    max-width: 100%;
    border-radius: 100%;
  }
}

.separadorField {
  display: flex;
  flex-direction: column;
  margin: 14px 0;

  form {
    display: flex;
    flex-direction: column;

    input[name="file"] {
      padding: 0;
      height: 40px;
      margin-top: 8px;
    }

    button {
      margin-top: 0;
      max-width: 310px;
    }
  }


  button {
    margin-top: 22px;
  }

  label {
    margin-bottom: 8px;
    display: block;
  }

  select,
  input {
    height: 40px;
    padding: 0 12px;
  }
}

.contentHorarios__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-right: 12px;
  border: 1px solid #111;
  padding: 18px 14px;
  cursor: pointer;

  &:hover {
    background-color: #ededed;
  }

  &.ativo {
    transition: .5s;
    transform: rotate(0deg);
  }

  span {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAB/klEQVR4nO2WOUsDURSFPyXaaFxabV2xcC8F0d7ob1HjD3BpRRFs3P6DlcYI/gm3yq3VwmxVjDw4A48wzrx5UVDwwIXJ5Lx7z7ztXPjHH8MgsAZcADdAUWGec0AWGPiJwlNAHqg5hhE4+R2FW4A94EOJX4EDYEGz0aYwzxngUJyaxuwCKd/i3cClkpWAdaDDYZzhbABlaza6fL48rwQvWoKkGAUelOMKaE0yeE8Dn4CeCF6z4iuYsc/KteNafErrZ6Z9PKb4oSJKxISWo6rnWOSleN2heLDr40RsimeOaiQGrd3e4VjcRUQn8CZef5SANStZXPGSVbzkIOJYnNUoATmRMg7F5ywBcw4ilvT/eZSAe5H66t43AftW8Xm9DwQYzAAF/T4KEREs722UgHeR0nXvh3XnB19OiACsmShqjI20uO8+Agxm64qHCUAcw8VHwJ1Iro4WJuArDIlrnNNrEzYqINiEZ1GkbMwxbETAibjLjV5EPgKcLyJknzVZ6ncJ2HKZ/gCTMqOyg3m4CDDmVpEZjeGIXSU2VtqLP3otO95OMjBlLYVpSKY9ipuvfbRcMHFr1mWJKMtSzWaKQ6fWvGLd/YlbsgApdTJVJXuTqy3qYmlXDOmcH1u7vapp925KbYwApwna8pxr95MU/fLzc12pBcW1jthKiJP+43fjE9lgym0PmL61AAAAAElFTkSuQmCC");
    display: inline-block;
    width: 32px;
    height: 32px;
    position: relative;
    transform: rotate(180deg);
    transition: .5s;
  }
}

.contentHorarios__conteudo {
  display: flex;
  justify-content: start;
  flex-direction: column;
  margin-bottom: 12px;
  padding-right: 12px;
  border: 1px solid #111;
  padding: 18px 14px;
}

.contentHorarios__inner {
  display: flex;
  justify-content: start;
  flex-direction: column;
  // overflow-y: auto;
  margin-bottom: 8px;
  @include scrollBar(6px, #111, #ddd);
  padding-right: 12px;
  // border: 1px solid #111;

  >a {
    color: #111;
  }

  label {
    font-size: $f15;
    margin-bottom: 8px;
  }
}

.titleSecao {
  text-transform: uppercase;
  font-size: $f14;
}

.title {
  font-size: $f16;
  font-weight: 600;
  margin-bottom: 28px;
  text-align: center;
  margin: 28px 0 0;
}

.info {
  margin-bottom: 42px;

  a {
    color: #111;
    text-decoration: underline;
  }
}

.horarios {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 3px;
  max-width: 96%;
  width: 580px;
  padding: 24px 12px;

  &__field {
    margin-bottom: 22px;
    height: 42px;
    border: 1px solid #ddd;
    padding: 12px;
    box-sizing: border-box;
  }

  &__listaHorariosAusentes {
    ul {
      display: flex;
      flex-wrap: wrap;
      border: 1px solid #ddd;
      padding: 12px;
      margin-bottom: 22px;

      li {
        margin: 4px 12px;
        background: #111;
        color: #fff;
        padding: 6px 10px;
        border-radius: 3px;
      }
    }
  }
}