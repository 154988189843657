@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import './styles/variables.scss';

*{
  box-sizing: border-box;
}

// escondendo erros na tela do browser 
#webpack-dev-server-client-overlay{
  display: none!important;
}

.wrap-site {
  // display: grid;
  // grid-template-rows: auto 1fr auto; // Header, Main, Footer
  // height: 100vh;
}

header {
  grid-row: 1; // Aligns to the top
}

main {
  grid-row: 2; // Takes up the remaining space
}

footer {
  grid-row: 3; // Aligns to the bottom
}

body,
html {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: $f16;
}

button,
input,
select{
  max-width: 100%;

  &:focus{
    outline: 0;
  }
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,h2,h3,h4{
  margin: 0;
}

a {
  text-decoration: none;
}