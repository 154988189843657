.notfound{
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 96%;
  width: 1180px;
  margin: 42px auto;
  min-height: 48vh;
  overflow-y: auto;

  p,
  a,
  h1{
    text-align: center;
  }

  a{
    color: #111;
    text-decoration: underline;
  }
}