@import 'styles/variables.scss';

.contentLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  // min-height: 57vh;
}

.button{
  text-align: center!important;
}

.login {
  display: flex;
  flex-direction: column;
  margin: 42px 0;
  border: 1px solid #cdcddd;
  border-radius: 3px;
  max-width: 96%;
  width: 420px;
  padding: 24px;
  height: max-content;

  &__fieldSenha{
    position: relative;
  }

  &__fieldSenha{
    input{
      width: 100%;
    }
  }

  &__title {
    font-size: $f19;
    font-weight: 600;
    margin-bottom: 8px;
    text-align: center;
  }

  &__cadastrar {
    margin-top: 8px;
    color: #111;
    font-size: 14px;

    &:hover {
      text-decoration: underline;
    }
  }

  label {
    font-size: $f15;
    margin-bottom: 8px;
  }

  &__field {
    margin-bottom: 22px;
    height: 40px;
    border: 1px solid #cdcddd;
    padding: 12px;
    box-sizing: border-box;
  }
}