@import 'styles/variables.scss';

.agendamentos {
  display: flex;
  flex-direction: column;
  max-width: 96%;
  width: 580px;
  // min-height: 57vh;
  margin: 42px auto;
  overflow-y: auto;

  &__buttons{
    display: flex;

    button{
      margin-right: 18px;

      &:last-child{
        margin-right: 0;
      }
    }
  }

  &__textInfoLink,
  &__textInfo {
    text-align: center;
  }

  &__textInfoLink {
    color: #111;
    text-decoration: underline;
  }

  &__title {
    font-size: $f19;
    font-weight: 600;
    margin-bottom: 24px;
    text-align: center;
  }

  &__field{
    min-height: 32px;
    margin-bottom: 22px;
    padding: 4px 12px;
  }

  &__titleFiltro{
    font-size: $f15;
    margin-bottom: 8px;
  }

  &__filtro{
    margin-bottom: 12px;
    min-height: 32px;
    border: 1px solid #666;
    border-radius: 3px;
    padding: 4px 12px;
  }

  &__list {
    overflow-y: auto;
    @include scrollBar(8px, #111, #ddd);
    padding-right: 12px;
    height: 400px;
  }

  &__item {
    border: 1px solid #ddd;
    border-radius: 3px;
    padding: 14px;
    margin-bottom: 22px;

    p {
      margin: 0 0 4px;
      font-size: $f16;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__button {
    text-align: right;
  }
}