@import 'styles/variables.scss';

.tabelaPrecos {
  display: flex;
  flex-direction: column;
  max-width: 96%;
  width: 1180px;
  margin: 42px auto;

  table {
    border: 1px solid #ddd;
    border-radius: 3px;
    margin-bottom: 22px;
    border-collapse: collapse;
    width: 100%;
    margin: 0 auto;

    tr,
    th,
    td {
      margin: 0;
    }

    td {
      padding: 4px 12px;
      text-align: center;
      border: 1px solid #ddd;
      font-size: $f15;
    }

    thead {
      background-color: #ddd;

      th {
        padding: 4px 12px;
        font-size: $f15;
      }
    }
  }
}

.select {
  height: 40px;
  padding: 0 12px;
  border: 1px solid #bbb;
}

.servicosInput {
  background-color: #ddd;
}

.wrapTable {
  overflow-x: auto;


  table {
    margin-bottom: 0;
  }
}

.formEdicao {
  border: 1px solid #ddd;
  padding: 14px;
  margin-top: 22px;

  .salvar {
    max-width: 328px;
    width: 100%;
    margin: 0 auto;
  }

  .fieldSeparador {
    display: flex;
    flex-direction: column;
    margin-bottom: 22px;

    label {
      font-weight: 600;
      font-size: $f15;
      margin-bottom: 4px;
    }

    input {
      height: 40px;
      padding: 6px 14px;
      border: 1px solid #bbb;
    }
  }
}