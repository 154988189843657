// fonts 
$f12: .75rem;
$f14: .88rem;
$f15: .95rem;
$f16: 1rem;
$f28: 1.75rem;
$f17: 1.07rem;
$f19: 1.2rem;

// THEME 
.primaryTheme1 {
  background-color: #B22222;
}

.primaryTheme2 {
  background-color: #20B2AA;
}

.primaryTheme3 {
  background-color: #4682B4;
}

.primaryTheme4 {
  background-color: #000;
}

.primaryTheme5 {
  background-color: #FF69B4;
}

// icons 
$hide: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABXElEQVR4nN2Vu0oDQRSGvyJewA2IYlAI5Al8A8EIYq1vIeQJtNHSZ7AQUlmbWIuYysLG9VKpkJDSQoIKgq6s/MgwzNlNhEX0wLAwc875Zs9t4D/KVJHOd4FbYKGom8dAom9F+2XgEHgAnoFXoAccAw1gZhRIxYCsA2/a99cA2AJKw0IWgfcRIQlwDlTznM8DN55h7EAmFMo60ApA7oCa5Tw1vDZuFzsQV7YDuukFoxBgP6B8auTElXbArukrLRk3X5XTbgZkxbBNw/gtJ4bSrM4fM8JVNmw7LqBjKEU672fkZNKorjMXUM/5zb2cxG94kA9g2c9DM+CkpbNxQfoKVzcHchAohq9w+D2QqBR9SZ1eGc14kTUwa2oWH9JWtURK/JpKOBSuMXKkqrZPRlxWnwSlpAE2GMLxE3D/EwgaxQ2N5p5G9YuSfARsAtOBKVzIozUHXAI7/NXnll+RT3SJxryK+e+1AAAAAElFTkSuQmCC";
$show: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVR4nO2UvU5CQRCFv4poxELFzkIT8TVAan9ewehDGJFefBsE30ILLbil2lzt4QEwk5xCNzOXxcJQ8CWT3MycM7uZ3buwYtnZBq6AIVAAU0Wh3KU0C7MO9NRsNidMcytPFnvAc0bjWRKvwMG85kfAl2MeAC1gQ9HWiFKdeZtR813gwzFdV2yo6+jfgYYnfgx2btSAe+ATKIG+csbI8T2QcB7MtaV636lZzjgOvKc/FygCUV310qlZztgMvON/XeAsELUrRnSnWifwnqTn4F27oWo1LVI6h1x1OX7R0BVLxV1ieo7+DdiJDM3gRxvpttQVnWDn5j0k46l4+sNT8QLsk8maRjPNaDwBbuRZmC3gQoc2VrOJvgeqmWbFEvMNriXJVcp1zQIAAAAASUVORK5CYII=";

.sucesso {
  $sucesso: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCI+CjxwYXRoIGQ9Ik0gMjUgMiBDIDEyLjMwOTUzNCAyIDIgMTIuMzA5NTM0IDIgMjUgQyAyIDM3LjY5MDQ2NiAxMi4zMDk1MzQgNDggMjUgNDggQyAzNy42OTA0NjYgNDggNDggMzcuNjkwNDY2IDQ4IDI1IEMgNDggMTIuMzA5NTM0IDM3LjY5MDQ2NiAyIDI1IDIgeiBNIDI1IDQgQyAzNi42MDk1MzQgNCA0NiAxMy4zOTA0NjYgNDYgMjUgQyA0NiAzNi42MDk1MzQgMzYuNjA5NTM0IDQ2IDI1IDQ2IEMgMTMuMzkwNDY2IDQ2IDQgMzYuNjA5NTM0IDQgMjUgQyA0IDEzLjM5MDQ2NiAxMy4zOTA0NjYgNCAyNSA0IHogTSAzNC45ODgyODEgMTQuOTg4MjgxIEEgMS4wMDAxIDEuMDAwMSAwIDAgMCAzNC4xNzE4NzUgMTUuNDM5NDUzIEwgMjMuOTcwNzAzIDMwLjQ3NjU2MiBMIDE2LjY3OTY4OCAyMy43MTA5MzggQSAxLjAwMDEgMS4wMDAxIDAgMSAwIDE1LjMyMDMxMiAyNS4xNzc3MzQgTCAyNC4zMTY0MDYgMzMuNTI1MzkxIEwgMzUuODI4MTI1IDE2LjU2MDU0NyBBIDEuMDAwMSAxLjAwMDEgMCAwIDAgMzQuOTg4MjgxIDE0Ljk4ODI4MSB6Ij48L3BhdGg+Cjwvc3ZnPg==";
  background-image: url($sucesso);
  width: 50px;
  height: 50px;
  display: inline-block;
}

.erro {
  $erro: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAC8ElEQVR4nO2ZW4hNURjHf2aMy7gO0QhDrrmlUWRCIsWDywMPg6JGlHjgZV6QiaYQuT3wYF4oE0UUUZLGRNFEI5OUZDAuTe7369aq/9RubOfs2zl7b51ffbVa6/vW+n/nsvZa34YcORLLMqCchDMJ+CWbQkLpANQDluy6+hLHciXwQmapL1EUAs0SXwGsUvsp0I0EsV3CbwF5spvq20ZCGAx8kugZtv4y4DfwGRhKAjipJI47jNVq7AQxZ5rtUx/iMD4I+KhkZhJT8oAGidyawq9KPreBfGLIGgl8kmZn6go8ku9qYkZP4LnEuTmOLJXvS6A3MWKPj6f3VcXsJiaMAL7qPDXZQ1ypYr4Do4kB5/XJHvERW6PYc0TMPAl5BxQ7jHcCimSm3Z5ixVqaKxIKgHsSUfkPny22069pO1GpcTOXmTPrbJSAB0DnNM8MS20nzDd1Xz4byDJ9gFdafGEKPzeJGBbJ5w3QjyxyWAtfTuPnNhHDRfkdIkuMA34AP4EJISYyVluxmXciWeCKhB1w4eslEcNB+dZn+lq8RAu9BvpmIJEioFX+i8kQXYCHWmSdyxiviRjWy79ZV+bQ2awFmjzs934SyQfuKGYTITMQ+KDJ53qI85OIYbZizJW5hBA5ponPeIzzm4jhrOKOEhJTdX39BozKYiLDdao2a08nIGYLvCEhO3zEB0nEsFOxDbpK+2alrVrYK4JEegDPFL8Cn3QHWmzVwiA7naW2HyoU3yJNnqkO4WstsN1HCkKozlR7DR4GfNEfzV4tjIoy24Yz0kvg6RTVwqiolaZTbgNmhfgwatt1jO0KOFeJraZsNKY9HjS6qBa6Za8tEdMOSpXmugt0TOW4Vo6PQzqwhZ1IobRZ0pr2CB3Wy0vz+mCOzKmo7YdyaWyV5r/YL4drCXjXVyet+9oPjNE102u1MCpKpdVcucfbBy4EqBZGRY00X2rrWKCO98AAkkN/4K20z0c3Pivh1mQv7yfZ6qL+eeT4b/kDXLNVbksXjZ8AAAAASUVORK5CYII=";
  background-image: url($erro);
  width: 50px;
  height: 50px;
  display: inline-block;
}

input[disabled] {
  background-color: #eee;
}

.fieldLogin {
  position: absolute;
  right: 12px;
  top: 8px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  cursor: pointer;


  &.password {
    background-image: url($hide);
  }

  &.text {
    background-image: url($show);
  }
}

.icon.true {
  transition: .5s;
  transform: rotate(0deg);
}

select[name="horarioFunc"],
select[name="funcionarioConfig"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L7 7L13 1' stroke='%23333333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center right 10px;
  height: 40px;
  padding: 12px;
  border: 1px solid #ddd;
}

label[for="horarioFunc"] {
  margin-top: 22px;
}

.lista-trabalho {
  border: 1px solid #ddd;
  padding: 12px;
  margin-bottom: 22px;

  li {
    margin-top: 8px;
  }
}


// buttons 
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 12px;
  // background-color: #111;
  color: #fff;
  min-height: 38px;
  border-radius: 3px;
  border: 0;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, .3);
  text-transform: uppercase;
  font-size: $f12;
  letter-spacing: 0.8px;
  cursor: pointer;

  &:hover {
    outline: 0;
    background: rgb(0, 1, 17);
    background: -moz-linear-gradient(90deg, rgba(0, 1, 17, 1) 64%, rgba(204, 204, 204, 1) 100%);
    background: -webkit-linear-gradient(90deg, rgba(0, 1, 17, 1) 64%, rgba(204, 204, 204, 1) 100%);
    background: linear-gradient(90deg, rgba(0, 1, 17, 1) 64%, rgba(204, 204, 204, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000111", endColorstr="#cccccc", GradientType=1);
    color: #fff;
    box-shadow: none;
    border: 1px solid #111;
    font-weight: 500;
  }

  &.editar,
  &.deletar {
    margin: 0 auto;
  }

  &.salvar-servico {
    width: 100%;
  }

  &.lista-dias {
    max-width: 310px;
    margin-top: 0;
  }

  &.servicos,
  &.limpar-selecao,
  &.todos-horarios {
    margin-bottom: 22px;
  }

  &.todos-horarios {
    margin-top: 22px;
  }

  &.limpar-selecao {
    &.servicos {
      margin-top: 8px;
      max-width: 310px;
    }
  }

  &.esqueci_senha {
    margin-top: 12px;
  }
}

.naopago {
  color: tomato;
}

.pago {
  color: #28a745;
}



.selected {
  background-color: #111;
  color: #fff;
}

.center {
  text-align: center;
}

.active {
  border-bottom: 0.5px solid #fff;
  padding-bottom: 3px;
  transition: 0.5s;
  text-decoration: underline;
}

.title {
  font-size: $f19;
  font-weight: 600;
  margin-bottom: 28px;
  text-align: center;
}

#open-modal {
  display: flex;
}

@mixin scrollBar($size, $bgColor, $bgBar) {
  &::-webkit-scrollbar {
    height: $size;
    width: $size;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar-track {
    background: $bgBar;
  }

  &::-webkit-scrollbar-thumb {
    height: $size;
    width: $size;
    border-radius: $size;
    background-color: $bgColor;
  }
}

.corTemaLista {
  display: flex;
  flex-wrap: wrap;

  li {
    width: 40px;
    height: 40px;
    cursor: pointer;
    margin-right: 14px;

    &.cor-selected {
      border: 2px solid #111;

      &.cor-000 {
        border: 2px solid #666;
      }
    }


    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border: 2px solid #111;
    }

    &.cor-000 {
      &:hover {
        border: 2px solid #666;
      }
    }
  }
}

// temas botoes
$corTema1: #d32f2f;
$corTema2: #000;
$corTema3: #1e88e5;
$corTema4: #26a69a;
$corTema5: #ec407a;
$corRGBTema1: 211 47 47;
$corRGBTema2: 0 0 0;
$corRGBTema3: 30 136 229;
$corRGBTema4: 38 166 154;
$corRGBTema5: 236 64 122;

.cor-20B2AA {
  background-color: $corTema4;
}

.cor-4682B4 {
  background-color: $corTema3;
}

.cor-000 {
  background-color: $corTema2;
}

.cor-B22222 {
  background-color: $corTema1;
}

.cor-FF69B4 {
  background-color: $corTema5;
}

body[tema="#B22222"] {

  .button,
  button {
    background-color: $corTema1;

    &:hover {
      background: rgb($corRGBTema1);
      border: 0;
      box-shadow: 2px 2px 4px rgba(55, 71, 79, 0.7);
    }
  }

  .selected {
    background-color: $corTema1;
  }

  .style_agendar__servicos__HoX\+4 li:hover {
    background-color: $corTema1;
  }

  .loader {
    border-top: 14px solid $corTema1;
    /* Blue */
  }
}

body[tema="#000"] {

  .button,
  button {
    background-color: $corTema2;

    &:hover {
      background: rgb($corRGBTema1);
      border: 0;
      box-shadow: 2px 2px 4px rgba(55, 71, 79, 0.7);
    }
  }

  .selected {
    background-color: $corTema2;
  }

  .style_agendar__servicos__HoX\+4 li:hover {
    background-color: $corTema2;
  }

  .wrap-loader {
    p {
      text-shadow: 0px 0px 22px $corTema2;
    }
  }

  .loader {
    border-top: 14px solid $corTema2;
    /* Blue */
  }
}

body[tema="#1e88e5"] {

  .button,
  button {
    background-color: $corTema3;

    &:hover {
      background: rgb($corRGBTema3);
      border: 0;
      box-shadow: 2px 2px 4px rgba(55, 71, 79, 0.7);
    }
  }

  .selected {
    background-color: $corTema3;
  }

  .style_agendar__servicos__HoX\+4 li:hover {
    background-color: $corTema3;
  }

  .wrap-loader {
    p {
      text-shadow: 0px 0px 22px $corTema3;
    }
  }

  .loader {
    border-top: 14px solid $corTema3;
    /* Blue */
  }
}

body[tema="#26a69a"] {

  .button,
  button {
    background-color: $corTema4;

    &:hover {
      background: rgb($corRGBTema4);
      border: 0;
      box-shadow: 2px 2px 4px rgba(55, 71, 79, 0.7);
    }
  }

  .selected {
    background-color: $corTema4;
  }

  .style_agendar__servicos__HoX\+4 li:hover {
    background-color: $corTema4;
  }

  .wrap-loader {
    p {
      text-shadow: 0px 0px 22px $corTema4;
    }
  }

  .loader {
    border-top: 14px solid $corTema4;
    /* Blue */
  }

}

body[tema="#ec407a"] {

  .button,
  button {
    background-color: $corTema5;

    &:hover {
      background: rgb($corRGBTema5);
      border: 0;
      box-shadow: 2px 2px 4px rgba(55, 71, 79, 0.7);
    }
  }

  .selected {
    background-color: $corTema5;
  }

  .style_agendar__servicos__HoX\+4 li:hover {
    background-color: $corTema5;
  }

  .wrap-loader {
    p {
      text-shadow: 0px 0px 22px $corTema5;
    }
  }

  .loader {
    border-top: 14px solid $corTema5;
    /* Blue */
  }
}

.wrap-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 99999;

  p {
    color: #fff;
    font-weight: bold;
    font-size: 15px;
  }
}

.loader {
  border: 14px solid #f3f3f3;
  /* Light grey */
  border-radius: 50%;
  width: 65px;
  height: 65px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}