@import 'styles/variables.scss';

.footer {
  position: relative;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 1px 2px 18px rgba(0, 0, 0, .5);
  height: auto;
  padding: 22px 0 0;

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ededed;
    margin-top: 18px;
    padding: 12px 0 14px;

    a{
      color: #111;
    }

    p {
      color: #111;
      font-weight: 500;
      margin: 0;
      font-size: $f12;
      text-align: center;
    }
  }


  &__container {
    width: 96%;
    max-width: 1920px;
    margin: 0 auto;
  }

  &__redes {
    a {
      color: #fff;
    }
  }

  img {
    filter: invert(1);
    padding-right: 8px;
    position: relative;
    top: 4px;
  }

  &__whats {
    display: flex;
    margin: 14px 0;

    a {
      color: #fff;
    }

    p {
      margin: 0;
    }
  }

  &__top {
    display: flex;
    color: #fff;

    ul {
      display: flex;
      justify-content: space-between;
      width: 100%;

      @media (max-width: 1024px) {
        flex-direction: column;
      }

      li {
        max-width: 420px;
        width: 100%;
        padding: 0 28px 0 0;

        @media (max-width: 1024px) {
          margin-bottom: 42px;
          padding: 0 12px;
        }

        h3 {
          font-size: $f16;
          font-weight: 500;
        }

        strong {
          font-size: $f14;
        }

        p {
          font-size: $f14;
        }

        iframe {
          width: 100%;
          height: 154px;
          max-width: 100%;
          border: 0;
          border-radius: 3px;
          max-width: 310px;
        }
      }
    }
  }
}

.horarios{
  max-width: 310px;
}