@import 'styles/variables.scss';

.contentAgendar {
  display: flex;
  justify-content: center;

  @media (max-width: 1179px) {
    flex-direction: column;
  }

  img {
    margin: 42px;

    @media (max-width: 1179px) {
      margin: 32px auto 8px;
      display: block;
    }
  }
}

.agendarFoto {
  img {
    width: 200px;
    height: 200px;
    border-radius: 100%;
  }
}

.wrapTotal {
  display: flex;
}

.agendar {
  display: flex;
  flex-direction: column;
  margin: 42px 0;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  max-width: 96%;
  width: 580px;
  padding: 24px 18px;
  min-height: 48vh;

  &__info {
    color: tomato;
    margin-bottom: 28px;
  }

  @media (max-width: 1179px) {
    margin: 14px auto 42px;
  }

  h2 {
    font-size: $f19;
    font-weight: 600;
    margin-bottom: 28px;
    text-align: center;
  }

  select {
    option:disabled {
      display: none;
    }
  }

  label {
    font-size: $f15;
    margin-bottom: 8px;
  }

  &__total {
    margin: 0 0 22px 4px;
  }
}