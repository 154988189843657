@import 'styles/variables.scss';

.agendar {
  display: flex;
  flex-direction: column;
  margin: 42px 0;
  border: 1px solid #cdcdcd;
  border-radius: 3px;
  max-width: 96%;
  width: 580px;
  padding: 24px 18px;
  min-height: 48vh;

  &__servicos {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 6px;
  }
}

