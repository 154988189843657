@import 'styles/variables.scss';

.modal {
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 999999999999999;

  &__conteudo {
    position: relative;
    width: 92%;
    max-width: 520px;
    height: auto;
    background-color: #fff;
    border-radius: 3px;
    padding: 44px 16px;

  }

  &__fechar {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 32px;
    background: #ddd;
    padding: 0px 11px;
    border-radius: 0 0 0 3px;
    cursor: pointer;

    @media (max-width: 1024px){
      background-color: #111;
      color: #fff;
    }

    &:hover{
      background-color: #111;
      color: #fff;
    }
  }

  &__status{
    display: flex;
    justify-content: center;
    max-width: 100%;
    margin-bottom: 22px;
  }
  
  &__mensagem{
    color: #111;
    max-width: 100%;
    text-align: center;
  }
}